import { PAYMENT_TYPES } from '../../../constants';

import generic from './GenericSubscriptionPane';
import Apple from './AppleSubscriptionPane';
import Loading from './LoadingPane';

const Klarna = generic(PAYMENT_TYPES.KLARNA, true);
const Mondido = generic(PAYMENT_TYPES.MONDIDO, false);
const Stripe = generic(PAYMENT_TYPES.STRIPE, true);
const GoldenTicket = generic(PAYMENT_TYPES.GOLDEN_TICKET, false);
const Giftcard = generic(PAYMENT_TYPES.GIFT_CARD, false);

const PaymentTypePanels = {
  [PAYMENT_TYPES.APPLE]: Apple,
  [PAYMENT_TYPES.MONDIDO]: Mondido,
  [PAYMENT_TYPES.STRIPE]: Stripe,
  [PAYMENT_TYPES.GIFT_CARD]: Stripe,
  [PAYMENT_TYPES.KLARNA]: Klarna,
  [PAYMENT_TYPES.NONE]: Stripe,
  [PAYMENT_TYPES.GOLDEN_TICKET]: GoldenTicket,
  [PAYMENT_TYPES.GIFT_CARD]: Giftcard,
  [PAYMENT_TYPES.SHOPIFY_GIFT_CARD]: Giftcard,
  default: Loading,
};

export const getSubscriptionPane = (paymentType) => {
  return PaymentTypePanels[paymentType] || PaymentTypePanels.default;
};
