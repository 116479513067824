import tr from '../../translations';

const {MONTHS, CURRENCY, SUBSCRIPTION} = require('../../config');

export function formatDate(d) {
  const date = new Date(d);
  return (parseInt(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + parseInt(date.getFullYear()));
}

export function toIsoDate(d) {
  const date = new Date(d);
  const twoDigits = (num) => `${num}`.padStart(2, '0');
  return `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`
}

export function priceString(string, offer = null) {
  let perPeriod = '';
  if (offer?.discountPeriodCount > 1) {
    perPeriod = '/' + SUBSCRIPTION.SINGULAR[offer.subscriptionType];
  }
  const num = parseFloat(string);

  let amountStr = num.toFixed((num % 1) === 0 ? 0 : 2);
  amountStr = amountStr.replace('.', ',');

  return `${amountStr} ${CURRENCY}${perPeriod}`
}

export function subscriptionOfferString(offer) {
  if (offer.discountPeriodCount === 1) {
    return `${tr('profile.subscription.offer-firstMonth')} ${SUBSCRIPTION.SINGULAR[offer.subscriptionType]}`
  }
  return `i ${offer.discountPeriodCount} ${SUBSCRIPTION.PLURAL[offer.subscriptionType]}`;
}
