import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { connect } from 'react-redux';

import { BaseModal } from './index';
import { checkEmail } from '../../../lib/utils';
import { updateEmail } from '../../../lib/api';
import { getSessionId } from '../../../lib/storage';
import { userEmailUpdated } from '../../../redux/actions';

import tr from '../../../../translations';

const ChangeEmailModal = ({ currentEmail, setEmailAction, show, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      if (!checkEmail(email)) {
        alert(tr('changeEmail.error.incorrectEmail'));
        return false;
      }

      if (email !== emailConfirm) {
        alert(tr('changeEmail.error.noMatch'));
        return false;
      }

      await updateEmail(email, password, getSessionId());
      setEmailAction(email);
      setPassword('');
      setEmail('');
      setEmailConfirm('');
      onClose(true);
    } catch (ex) {
      alert(ex.message);
    }
    finally {
      setWaiting(false);
    }
  };

  const handleClose = () => {
    setPassword('');
    setEmail('');
    setEmailConfirm('');
    onClose(false);
  };

  return (
    <BaseModal
      title={`${tr('changeEmail.title')} ${currentEmail}`}
      show={show}
      waiting={waiting}
      onClose={handleClose}
      onSave={handleSave}
    >
      {tr('changeEmail.confirmPassword-label')}
      <InputGroup className="mb-3">
        <FormControl
          aria-label={tr('changeEmail.confirmPassword-placeholder')}
          placeholder={tr('changeEmail.confirmPassword-placeholder')}
          type="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
      </InputGroup>
      {tr('changeEmail.email-label')}
      <InputGroup className="mb-3">
        <FormControl
          aria-label={tr('changeEmail.email-placeholder')}
          placeholder={tr('changeEmail.email-placeholder')}
          type="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </InputGroup>
      {tr('changePassword.confirmPassword-label')}
      <InputGroup className="mb-3">
        <FormControl
          aria-label={tr('changeEmail.email-placeholder')}
          placeholder={tr('changeEmail.email-placeholder')}
          type="email"
          value={emailConfirm}
          onChange={({ target: { value } }) => setEmailConfirm(value)}
        />
      </InputGroup>
    </BaseModal>
  );
};

export default connect(({ user }) => ({ currentEmail: user.email }), {
  setEmailAction: userEmailUpdated,
})(ChangeEmailModal);
