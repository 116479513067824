import React, { useContext } from "react";

// import { AccordionContext } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";

import closeIcon from "../../images/close.svg";
import tr from "../../../translations";

const ContextForIcon = ({ eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const isOpen = currentEventKey === eventKey;
  return (
    <div className={`toggleIcon-container${isOpen ? ' open' : ''}`}>
      <img className="toggleIcon" src={closeIcon} alt="" />
    </div>
  );
};
const ProfileCard = ({ children, eventKey, iconSource, headerTranslation }) => {
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Card.Header} variant="link" eventKey={eventKey}>
          <div className="card-header-icon-container">
            <img src={iconSource} alt="" className="card-header-icon" />
          </div>
          <h5>{tr(headerTranslation)}</h5>
          <ContextForIcon eventKey={eventKey} />
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ProfileCard;
