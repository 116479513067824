import React from 'react';

import tr from '../../../../translations';
import Receipt from './Receipt';
import AppstoreReceipt from './AppstoreReceipt';

const Receipts = ({ data }) => {
  if (data.status === 'error') {
    return <>{tr('profile.receipts-error')}</>;
  }
  if (!data.transactions) {
    return <></>;
  }
  if (data.transactions && data.transactions.length > 0) {
    return (
      <>
        {data.transactions.map((t) => {
          if (t.items[0].paymentType === 'appstore') return <AppstoreReceipt key={t.transactionTime} transaction={t}/>;
          else return <Receipt key={t.transactionTime} transaction={t} />;
        })}
      </>
    );
  }
  return <>{tr('profile.receipts-empty')}</>;
};
export default Receipts;
