import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import tr from '../../../../translations';
import CancelSubscriptionModal from './CancelSubscriptionModal';

import { sendTrackingEvent } from '../../../lib/utils';

const QUESTIONS = tr('cancelSub.reasons');

const CancelSubscription = ({
  show,
  onClose,
  onCancelSubscription,
  onSubmitWrittenFeedback,
  activeUntil,
  onGetCurrentOffer,
  onGetCancellationOffer,
  onApplyDiscountOffer,
  onRequestSupport
}) => {
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState({});
  const [feedback, setFeedback] = useState('');
  const [supportMessage, setSupportMessage] = useState('');
  const [currentOffer, setCurrentOffer] = useState('');
  const [cancellationOffer, setCancellationOffer] = useState({});
  const [primaryBtnDisabled, setPrimaryBtnDisabled] = useState(false);
 
  const handleClose = (reloadPage = false) => {
    onClose(reloadPage);

    // Reset form if it is opened up again
    setTimeout(() => {
      setStep(1);
      setReason({});
      setFeedback('');
      setSupportMessage('');
      setCurrentOffer('');
      setCancellationOffer({});
    }, 100);
  };

  const handleRequestSupport = async () => {
    if(supportMessage) {
        sendTrackingEvent('cancel-subscription-recovered', {context: 'requested-support'}, true);
        await onRequestSupport(supportMessage);

        handleClose();
    } else {
        document.getElementById('cta-writeSupportMessage').style.display = 'block';
    }
  };

  const ActiveOffer = (currentPrice, discount, discountPeriods, subscriptionType, defaultPrice) => {
    let currentOfferText;
    const percentage = Math.round((100 * (currentPrice-discount)) / defaultPrice, 1);
    if ([25, 50, 75, 100].includes(percentage)) {
        currentOfferText = tr('cancelSub.currentDiscount-percentageOf', percentage, subscriptionType);
    } else if (discountPeriods === -1) {
        currentOfferText = tr('cancelSub.currentDiscount-foreverDiscount', subscriptionType, currentPrice, defaultPrice);
    } else {
        currentOfferText = tr('cancelSub.currentDiscount-nextPaymentDiscount', subscriptionType, (currentPrice-discount), defaultPrice);
    }
    return currentOfferText;
  }

  const handleGetCurrentOffer = async () => {
    await onGetCurrentOffer().then((data) => {
        console.log("Got current offer data: ", data);
        if (!data || (data.status && data.status === 'error')) {
            handleGetCancellationOffer();
            setStep(3);
        } else {
            if(data.defaultPrice !== data.userPrice || (data.discountAmount !== 0 && data.discountPeriods !== 0)) {
                const currentOfferText = ActiveOffer(data.userPrice, data.discountAmount, data.discountPeriods, data.subscriptionType, data.defaultPrice);             
                setCurrentOffer(currentOfferText);
                setStep(2);
            } else {
                handleGetCancellationOffer();
                setStep(3);
            }
        }
    })
  }

  const handleGetCancellationOffer = async () => {
        await onGetCancellationOffer().then((data) => {
            if(!data || (data.status && data.status === 'error')) {
                setStep(4);
            } else {
                if(data.discountAmount !== 0 && data.discountPeriods !== 0) {
                    const offerData = {offerData: tr('cancelSub.cancellationOffer', data.subscriptionType, data.discountPeriods, (data.userPrice-data.discountAmount)), ...data};
                    setCancellationOffer(offerData);
                    setStep(3);
                } else if (data.subscriptionType === 'YEARLY' && data.offerName) {
                    const offerData = {offerData: tr('cancelSub.cancellationOffer-yearly', data.userPrice), ...data};
                    setCancellationOffer(offerData);
                    setStep(3);
                } else {
                    setStep(4);
                }
            }
        })
  };


  const handleApplyDiscountOffer = async () => {
    const offer = cancellationOffer.offerName;
    setPrimaryBtnDisabled(true);
    try {
        await onApplyDiscountOffer(offer);
        sendTrackingEvent('cancel-subscription-recovered', {context: 'churn-recovery-offer'}, true);
        handleClose(true);
    } catch (ex) {
        console.error(ex);
        window.location.reload();
    } finally {
      setPrimaryBtnDisabled(false);
    }
  }
  
  const handleDenyCancellationOffer = () => {
    setStep(4);
  }

  const handleCancelSubscription = async () => {
    if (Object.keys(reason).length) {
      const selectedReason =
        Object.keys(reason)
          .filter((r) => reason[r])
          .join(',') || 'none-selected';
        setPrimaryBtnDisabled(true);
      try {
        await onCancelSubscription(selectedReason);
        setStep(5);
      } catch (ex) {
        console.error(ex);
        window.location.reload();
      } finally {
        setPrimaryBtnDisabled(false);
      }

    } else {
      document.getElementById('cta-chooseOption').style.display = 'block';
    }
  };

  const handleSendFeedback = async () => {
    await onSubmitWrittenFeedback({feedback: feedback, reason: Object.keys(reason)[0]});
    handleClose(true);
  };

  const handleKeepCurrentPrice = () => {
    sendTrackingEvent('cancel-subscription-recovered', {context: 'keep-current-price'}, true);
    handleClose();
  }

  const handleCloseOnReasons = () => {
    sendTrackingEvent('cancel-subscription-recovered', {context: 'choose-cancellation-reasons'}, true);
    handleClose();
  }

  const handleSecondaryBtnClick = () => {
    if (step === 1) handleGetCurrentOffer();
    if (step === 2) handleGetCancellationOffer();
    if (step === 3) handleDenyCancellationOffer();
    if (step === 4) handleCloseOnReasons();
    if (step === 5) handleClose();
  }

  const handlePrimaryBtnClick = () => {
    if (step === 1) handleRequestSupport();
    if (step === 2) handleKeepCurrentPrice();
    if (step === 3) handleApplyDiscountOffer();
    if (step === 4) handleCancelSubscription();
    if (step === 5) handleSendFeedback();
  }


  let subtitle = tr('cancelSub-title', step, cancellationOffer.discountPeriods ?cancellationOffer.discountPeriods:'', cancellationOffer.userPrice ? (cancellationOffer.userPrice-cancellationOffer.discount): '', cancellationOffer.subscriptionType ? cancellationOffer.subscriptionType : '');
  let title = tr('cancelSub-subtitle', step);
  let primaryBtnCTA = tr('cancelSub-primaryCTA', step);
  let secondaryBtnCTA = tr('cancelSub-secondaryCTA', step);

  let body;

    // Step 1 - Contact support
  if (step === 1 ) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <p>
          {(tr('cancelSub.contactSupport')).text1}
          <br />
          <br />
          {(tr('cancelSub.contactSupport')).text2}
        </p>

        <hr />
        <Form.Control
          as="textarea"
          style={{ marginBottom: '6px' }}
          value={supportMessage}
          onChange={({ target: { value } }) => setSupportMessage(value)}
        />
        <br/>
        <br/>
        <p id="cta-writeSupportMessage" style={{ color: 'red', display: 'none' }}>
            {tr('cancelSub.contactSupport-CTA')}
        </p>
      </div>
    );
    // Step 2 - Inform that current subscription is discounted
  } else if ( step === 2) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <div style={{ padding: '0 1rem' }}>
          {currentOffer}
          <br/>
          <br/>
          {tr('cancelSub.currentDiscount-text')}
        </div>
      </div>
    );
    // Step 3 - Provide cancellation offer
  } else if (step === 3) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <div style={{ padding: '0 1rem' }}>
            {cancellationOffer.offerData}
            <br/>
            <br/>
            {tr('cancelSub.cancellationOffer-text')}
        </div>
      </div>
    );
    // Step 4 - Set cancellation reason 
  } else if (step === 4) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <div style={{ padding: '0 1rem' }}>
          {QUESTIONS.map((props) => (
            <Form.Check
              disabled={step === 5}
              key={props.id}
              {...props}
              type="radio"
              style={{ marginBottom: '6px' }}
              checked={reason[props.id] || false}
              onChange={({ target: { id, checked } }) => setReason({ [id]: checked })}
            />
          ))}
          <br/>
          <br/>
            <p id="cta-chooseOption" style={{ color: 'red', display: 'none' }}>
                {tr('cancelSub.chooseReason')}
            </p>
        </div>
      </div>
    );

    // Step 5 - Subscription cancelled, optional written feedback
  } else {
    subtitle = tr('cancelSub-title', true);
    title = tr('cancelSub-subtitle', true);
    body = (
      <div style={{ padding: '0 1rem' }}>
        {new Date(new Date(activeUntil).toDateString()) > new Date(new Date().toDateString()) ? (
          <h5 style={{ textAlign: 'left', color: '#604077' }}>
            {tr('cancelSub.endSub-activeUntil', activeUntil)}
          </h5>
        ) : (
          <div></div>
        )}
        <p>
          {(tr('cancelSub.feedback')).text1}
          <br />
          {(tr('cancelSub.feedback')).text2}
        </p>

        <p>
          {(tr('cancelSub.confirmation')).text1} <br />
          {(tr('cancelSub.confirmation')).text2} <span aria-label="hjärta" role="img">💜</span>
        </p>

        <hr />
        <Form.Control
          as="textarea"
          style={{ marginBottom: '6px' }}
          value={feedback}
          onChange={({ target: { value } }) => setFeedback(value)}
        />
      </div>
    );
  }

  return (
    <CancelSubscriptionModal 
        show={show}
        handleClose={handleClose}
        primaryBtnCTA={primaryBtnCTA}
        primaryBtnDisabled={primaryBtnDisabled}
        secondaryBtnCTA={secondaryBtnCTA}
        onPrimaryBtnClick={handlePrimaryBtnClick}
        onSecondaryBtnClick={handleSecondaryBtnClick}
        title={title}
        subtitle={subtitle} 
    >
    {body}
    </CancelSubscriptionModal>
  );
};
 
export default CancelSubscription;