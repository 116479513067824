import React from 'react';
import ChangeSubscriptionSection from '../../common/ChangeSubscriptionSection';
import tr from '../../../../translations';
import SubscriptionDetails from "./SubscriptionDetails";

export default (paymentType, showChangeSubscription) => {
  return ({
    subscriptionData,
    onShowCheckout,
    onShowCancelSubscriptionQuestion,
    onShowChangeSubscription,
  }) => {
    return (
      <div>
        <div className="subscription-info">
          <SubscriptionDetails {...subscriptionData} />
        </div>
        <hr />
        {!subscriptionData.subscriptionState ? null : 
        subscriptionData.subscriptionState === 
        'GOLDEN_TICKET' ? (
          <button className="subscription-button" onClick={onShowCheckout}>
            {tr('profile.subscription.addPayment')}
          </button>
        ) : subscriptionData.subscriptionState === 
        'GIFT_CARD' ? (
          <button className="subscription-button" onClick={onShowCheckout}>
            {tr('profile.subscription.addPayment')}
          </button>
        ) : subscriptionData.subscriptionState ===
          'CHURN' ? (
          <button className="subscription-button" onClick={onShowCheckout}>
            {tr('profile.subscription.startSub')}
          </button>
        ) : subscriptionData.subscriptionState === 'LEAD' ? (
          <button className="subscription-button" onClick={onShowCheckout}>
            {tr('profile.subscription.startTrial')}
          </button>
        ) : subscriptionData.subscriptionState === 'GRACE' ? (
          subscriptionData.subscriptionType === 'QUARTERLY' ?
            <button
              style={{ border: '0px', textDecoration: 'underline', backgroundColor: 'transparent' }}
              onClick={onShowCancelSubscriptionQuestion}
            >
              {tr('profile.subscription.endSub', paymentType)}
            </button>
          :
          <>
            <button className="subscription-button" onClick={onShowCheckout}>
              {tr('profile.subscription.addPayment')}
            </button>
            <hr />
            <button
              style={{ border: '0px', textDecoration: 'underline', backgroundColor: 'transparent' }}
              onClick={onShowCancelSubscriptionQuestion}
            >
              {tr('profile.subscription.endSub', paymentType)}
            </button>
          </>
        ) : (
          <>
            {showChangeSubscription && (
              <>
                <ChangeSubscriptionSection
                  current={subscriptionData}
                  onShowChangeSubscription={onShowChangeSubscription}
                  submitChangesButtonText={tr('profile.subscription.changeSub', paymentType)}
                />
                <hr />
              </>
            )}
            <button
              style={{ border: '0px', textDecoration: 'underline', backgroundColor: 'transparent' }}
              onClick={onShowCancelSubscriptionQuestion}
            >
              {tr('profile.subscription.endSub', paymentType)}
            </button>
          </>
        )}
      </div>
    );
  };
};
