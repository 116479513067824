import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import { ButtonOld } from '../../common';
import { sendTrackingEvent } from '../../../lib/utils';

import tr from '../../../../translations';
import ClickableDiv from '../../common/ClickableDiv';

const styles = StyleSheet.create({
  page: {},
  section: {
    marginLeft: 10,
    marginBottom: 10,
    padding: 0,
  },
  section_title: {
    margin: 10,
    padding: 0,
  },
});
const PdfDocument = ({ text, title }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section_title}>
        <Text>{title}</Text>
      </View>
      <View style={styles.section}>
        <Text>{text}</Text>
      </View>
    </Page>
  </Document>
);

const trackPdfClick = () => {
  sendTrackingEvent('receipts_show_pdf');
};
const PdfButton = ({ text, title }) => (
  <ClickableDiv onClick={trackPdfClick}>
    <PDFDownloadLink
      document={<PdfDocument text={text} title={title} />}
      fileName={`${title}.pdf`}
      className="pdf-download-button"
    >
      {({ loading }) => (loading ? '' : tr('profile.receipt-pdf'))}
    </PDFDownloadLink>
  </ClickableDiv>
);
const ReceiptModal = ({ children, show, title, onClose }) => {
  const pdfText = children.filter((o) => !o.type).map((o) => o.toString() + '\n');

  return (
    <Modal show={show} onHide={onClose}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        <PdfButton text={pdfText} title={title} />
        <ButtonOld variant="primary" onClick={onClose}>
          Ok
        </ButtonOld>
      </Modal.Footer>
    </Modal>
  );
};
export default ReceiptModal;
