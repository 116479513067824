import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ButtonOld } from '../../common';

const CancelSubscriptionModal = ({
  children,
  show,
  handleClose,
  primaryBtnCTA,
  primaryBtnDisabled,
  secondaryBtnCTA,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  title,
  subtitle
}) => {
  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <div style={{ margin: '30px 30px 0px 30px' }}>
            {subtitle && <h5 style={{ textAlign: 'left', color: '#604077' }}>{subtitle}</h5>}
            {title && <h2 style={{ textAlign: 'left', marginTop: '10px' }}>{title}</h2>}
          </div>
        </Modal.Header>
        {children && <Modal.Body>{children}</Modal.Body>}
        <Modal.Footer>
            <ButtonOld className="white" onClick={() => {onSecondaryBtnClick()}} >
            {secondaryBtnCTA}
            </ButtonOld>
            <ButtonOld onClick={() => {onPrimaryBtnClick()}} disabled={primaryBtnDisabled}>          
            {primaryBtnCTA}
            </ButtonOld>
        </Modal.Footer>
      </Modal>
    </>
  );
};
 
export default CancelSubscriptionModal;
