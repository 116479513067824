import React from 'react';

import tr from '../../../../translations';
import { toIsoDate, priceString, subscriptionOfferString } from '../../../lib/subscription';

const { SUBSCRIPTION } = require('../../../../config');

const TranslatedLabel = ({ children, label }) => {
  return (
    <>
      <p className="small trial-period">{tr(label)}</p>
      {children}
    </>
  );
};

const TrialPeriod = ({ subscriptionState, nextPaymentInDays }) => {
  if (!Number.isInteger(nextPaymentInDays) || (subscriptionState !== 'TRIAL' && subscriptionState !== 'GOLDEN_TICKET')) return null;
  return (
    <TranslatedLabel label="profile.subscription.trialPeriod-header">
      <p>{tr('profile.subscription.trialPeriod-days', nextPaymentInDays)}</p>
    </TranslatedLabel>
  );
};

const ValidUntil = ({ subscriptionState, nextPaymentAt }) => {
  if (subscriptionState !== 'GIFT_CARD') return null;
  const date = toIsoDate(nextPaymentAt);
  return (
    <TranslatedLabel label="profile.subscription.validUntil-header">
      <p>{date}</p>
    </TranslatedLabel>
  );
};

const SubscriptionType = ({ subscriptionState, paymentType, subscriptionType, trialInDays }) => {
  const label = 'profile.subscription.type-header';

  if (subscriptionState === 'LEAD') {
    return (
      <TranslatedLabel label={label}>
        <p>{tr('profile.subscription.type-notActivated')}</p>
        <p>
          {tr('profile.subscription.addPayment-freeTrial').text1}
          <span style={{ display: 'inline-block' }}>
            {trialInDays} {tr('profile.subscription.addPayment-freeTrial').text2}
          </span>
        </p>
      </TranslatedLabel>
    );
  }
  if (subscriptionState === 'CHURN') {
    return (
      <TranslatedLabel label={label}>
        <p>{tr('profile.subscription.type-inactive')}</p>
      </TranslatedLabel>
    );
  }
  if (subscriptionState === 'GOLDEN_TICKET') {
    return (
      <TranslatedLabel label={label}>
        <p>{tr('profile.subscription.type-golden')}</p>
      </TranslatedLabel>
    );
  }
  if (subscriptionState === 'GIFT_CARD') {
    return (
      <TranslatedLabel label={label}>
        <p>{tr('profile.subscription.type-giftcard')}</p>
      </TranslatedLabel>
    );
  }

  const subscriptionPeriodType =
    SUBSCRIPTION.TYPES[subscriptionType] + (paymentType === 'APPLE' ? ' via Apple' : '');

  return (
    <TranslatedLabel label={label}>
      <p>{`${subscriptionPeriodType}`}</p>
    </TranslatedLabel>
  );
};

const NextPayment = ({ subscriptionState, nextPaymentAmount, nextPaymentAt }) => {
  if (subscriptionState === 'LEAD' || subscriptionState === 'GRACE' || subscriptionState === 'GOLDEN_TICKET'|| subscriptionState === 'GIFT_CARD' || !nextPaymentAt) return null;

  const className = subscriptionState === 'GRACE' ? 'red' : '';
  const amount = nextPaymentAmount ? priceString(nextPaymentAmount) : '';
  const date = toIsoDate(nextPaymentAt);

  return (
    <TranslatedLabel label="profile.subscription.nextPayment-header">
      <p className={className}>{amount} {date}</p>
    </TranslatedLabel>
  );
};

const Offer = ({ subscriptionState, offer }) => {
  if (subscriptionState !== 'CHURN' || !offer) return null;

  const subscriptionOffer = subscriptionOfferString(offer);
  return (
    <TranslatedLabel label="profile.subscription.offer-header">
      <p>{`${tr('profile.subscription.addPayment')}, ${priceString(
        offer.nextPaymentAmount,
        offer
      )} ${subscriptionOffer}`}</p>
    </TranslatedLabel>
  );
};

const ActiveOffer = ({
  discount,
  discountPeriodCount,
  nextPaymentAmount,
  price,
  subscriptionType,
}) => {
  if (!discount || !discountPeriodCount || !nextPaymentAmount) return null;

  const discountAmount = parseFloat(discount);
  const priceAmount = parseFloat(price);

  const percentage = Math.round((100 * discountAmount) / priceAmount, 1);
  let discountAmountText = '';

  if ([25, 50, 75, 100].includes(percentage)) {
    discountAmountText = `${percentage}%`;
  } else {
    discountAmountText = priceString(discountAmount);
  }

  return (
    <TranslatedLabel label="profile.subscription.active-offer-header">
      <p>
        {tr(
          'profile.subscription.active-offer-text',
          discountAmountText,
          discountPeriodCount,
          subscriptionType
        )}
      </p>
    </TranslatedLabel>
  );
};

const ContinueToUse = ({ subscriptionState, activeInDays }) => {
  if (subscriptionState !== 'CHURN' || !activeInDays) return null;

  return (
    <TranslatedLabel label="profile.subscription.activeDays-header">
      <p>{tr('subscription.activeInDays', activeInDays)}</p>
    </TranslatedLabel>
  );
};

const GraceInfo = ({ subscriptionState }) => {
  if (subscriptionState !== 'GRACE') return null;

  return <p>{tr('profile.subscription.grace-addPayment')}</p>;
};

const GoldenInfo = ({ subscriptionState, nextPaymentAt}) => {
  if (subscriptionState !== 'GOLDEN_TICKET' ) return null;
  return <p>{tr('profile.subscription.golden-addPayment', nextPaymentAt)}</p>;
}

const GiftcardInfo = ({ subscriptionState, nextPaymentAt}) => {
  if (subscriptionState !== 'GIFT_CARD' ) return null;
  return <p>{tr('profile.subscription.giftcard-addPayment', nextPaymentAt)}</p>;
}

const SubscriptionDetails = (props = {}) => {
  if (!props.subscriptionState) return null;

  return (
    <>
      <GoldenInfo {...props} />
      <GiftcardInfo {...props} />
      <GraceInfo {...props} />
      <TrialPeriod {...props} />
      <SubscriptionType {...props} />
      <ValidUntil {...props} />
      <ActiveOffer {...props} />
      <NextPayment {...props} />
      <ContinueToUse {...props} />
      <Offer {...props} />
    </>
  );
};

export default SubscriptionDetails;
