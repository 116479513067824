import React, { useState } from 'react';

import { InfoModal } from '../../common/modals';

import tr from '../../../../translations';
import ClickableDiv from '../../common/ClickableDiv';

import './Receipt.css'

const { SUBSCRIPTION } = require('../../../../config');

const Receipt = ({ transaction }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const dateFormat = tr('date-format');
  const date = new Date(transaction.transactionTime);
  const dateString = date.toLocaleDateString(dateFormat);
  const subscriptionType = (transaction.items[0].subscriptionType in SUBSCRIPTION.TYPES) ? 
    SUBSCRIPTION.TYPES[transaction.items[0].subscriptionType] :
    tr('profile.payment-history-subscription');

  function showModal() {
    setShow(true);
  }

  return (
    <>
      <InfoModal show={show} onClose={handleClose} title={tr('apple.receipt-header')}>
        {tr('apple-step1')}
        <br />
        {tr('apple-step2')}
        <br />
        {tr('apple.receipt-step3')}
        <br />
        {tr('apple.receipt-step4')}
        <br />
      </InfoModal>
      <ClickableDiv className="receipt-card-row" onClick={() => showModal()}>
        <span className="primary">
          {subscriptionType} via App Store
        </span>
        <br/>
        <span className="secondary">&nbsp;</span>
        <span className="secondary to-the-right">{dateString}</span>
      </ClickableDiv>
    </>
  );
};
export default Receipt;
