export const PAYMENT_TYPES = Object.freeze({
    APPLE: 'APPLE',
    MONDIDO: 'MONDIDO',
    STRIPE: 'STRIPE',
    KLARNA: 'KLARNA',
    GIFT_CARD: 'GIFT_CARD',
    NONE: 'NONE',
    GOLDEN_TICKET: 'GOLDEN_TICKET',
    SHOPIFY_GIFT_CARD: 'SHOPIFY_GIFT_CARD'
});
